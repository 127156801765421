import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Image from './Image';

const useStyles = makeStyles((theme) => ({
  mapTab: ({ tabView }) => ({
    display: tabView === 0 ? 'block' : 'none',
  }),
  photoTab: ({ tabView }) => ({
    display: tabView === 1 ? 'block' : 'none',
  }),
  wrapper: {
    height: '346px',
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  map: {
    width: '100%',
    height: '346px',
    border: 'none',
  },
}));

const SundayLocation = () => {
  const [showMap, setShowMap] = useState(false);
  const [tabView, setTabView] = useState(0);
  const classes = useStyles({ tabView });

  useEffect(() => {
    setShowMap(true);
  }, []);

  return (
    <>
      <Box mb={2}>
        <Box mb={1}>
          <Tabs
            value={tabView}
            onChange={(event, newView) => setTabView(newView)}
          >
            <Tab label="Map" />
            <Tab label="Photo" />
          </Tabs>
        </Box>

        <Box mb={2}>
          <div className={classes.mapTab}>
            <div className={classes.wrapper}>
              {showMap && (
                <iframe
                  title="Meeting Location"
                  src="https://www.openstreetmap.org/export/embed.html?bbox=-105.07605314254762%2C40.40410538020203%2C-105.0689721107483%2C40.407581591989015&amp;layer=mapnik&amp;marker=40.40584350853711%2C-105.07251262664795"
                  className={classes.map}
                />
              )}
            </div>
          </div>

          <div className={classes.photoTab}>
            <div className={classes.wrapper}>
              <Image
                src="/graphics/building.png"
                alt="River of Life Christian Fellowship"
              />
            </div>
          </div>
        </Box>
      </Box>

      <Box mb={2}>
        <Typography variant="body1">
          <strong>Address:</strong>{' '}
          <MuiLink href="https://goo.gl/maps/JWXs7TqEQ1Zx2CDQ7">
            1306 N Lincoln Ave, Loveland, CO 80537
          </MuiLink>
        </Typography>
      </Box>
    </>
  );
};

export default SundayLocation;
